import React, {useEffect, useState} from 'react';
import {styles} from './styles';
import {Card} from 'antd';
import QRCode from 'react-qr-code';
import {useParams} from 'react-router-dom';
import {PUBLIC_PREFIX_PATH} from 'configs/AppConfig';
import UrlEncryptUtil from 'utils/UrlEncryptUtil';

const moment = require('moment');

const ClienteSenha = () => {
	const [senha, setSenha] = useState('');

	let {id} = useParams();

	const getLogo = () => {
		return `${PUBLIC_PREFIX_PATH}/natercia.png`;
	};

	useEffect(() => {
		setSenha(UrlEncryptUtil.decrypt('&%sd4FSD3#$$', id));
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<div className="device-container">
			<header
				style={{
					backgroundColor: '#004C4D',
					height: '50px',
					width: '100%',
					position: 'fixed',
				}}>
				<div
					style={{
						margin: 'auto',
						width: 'min-content',
						paddingRight: '20px',
					}}>
					<img
						src={getLogo()}
						alt={`logo`}
						style={{
							marginTop: '5px',
							height: '40px',
						}}
					/>
				</div>
			</header>
			<div style={styles.main_container}>
				<Card align="center" style={styles.main_card}>
					<h1 style={styles.password_title}>Sua senha</h1>
					<h1 style={styles.password}>{senha}</h1>
				</Card>
				<div
					style={{
						margin: 'auto',
						textAlign: 'center',
					}}>
					<div style={styles.qr_code}>
						<QRCode style={{width: '100%', height: '100%'}} value={senha} />
					</div>
				</div>
			</div>
			<footer
				style={{
					position: 'fixed',
					backgroundColor: '#004C4D',
					height: '50px',
					width: '100%',
					bottom: 0,
				}}>
				<h5
					style={{
						color: '#fff',
						textAlign: 'center',
						fontWeight: 'lighter',
						margin: '5px',
					}}>
					{moment().format('DD/MM/YYYY HH:mm')}
				</h5>
			</footer>
		</div>
	);
};

export default ClienteSenha;
