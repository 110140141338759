const dev = {
	API_ENDPOINT_URL: 'http://localhost:8087/natercia/api',
	SOCKET_ENDPOINT_URL: 'http://localhost:8080',
	SITE_URL: 'http://localhost:3000',
	SITE_SUFIX_PATH: '',
	PUBLIC_PREFIX_PATH: '',
	API_SOCKET_ENDPOINT_URL: 'http://localhost:8087/',
	API_SOCKET_SUFIX_PATH: '/socket.io',
};

const prod = {
	API_ENDPOINT_URL: 'https://natercia-back-29rpe.ondigitalocean.app/natercia-back2/natercia/api',
	SOCKET_ENDPOINT_URL: 'https://natercia-back-29rpe.ondigitalocean.app',
	SITE_URL: '???',
	PUBLIC_PREFIX_PATH: '',
	API_SOCKET_ENDPOINT_URL: 'https://natercia-back-29rpe.ondigitalocean.app',
	API_SOCKET_SUFIX_PATH: '/natercia-back2/natercia/socket.io',
};

//todo ajustar depois para o back da natercia
// const test = {
//   API_ENDPOINT_URL: 'https://homolog.dimo.com.br/natercia-back/api',
//   SOCKET_ENDPOINT_URL: 'https://homolog.dimo.com.br',
//   PUBLIC_PREFIX_PATH: '/natercia',
//   SITE_URL: 'https://homolog.dimo.com.br',
//   SITE_SUFIX_PATH: '/natercia',
//   API_SOCKET_ENDPOINT_URL: 'https://homolog.dimo.com.br/',
//   API_SOCKET_SUFIX_PATH: '/natercia/socket.io'
// };

const test = {
	API_ENDPOINT_URL: 'https://homolog.dimo.com.br/natercia-back/api',
	SOCKET_ENDPOINT_URL: 'https://homolog.dimo.com.br',
	PUBLIC_PREFIX_PATH: '/natercia',
	SITE_URL: 'https://homolog.dimo.com.br',
	SITE_SUFIX_PATH: '/natercia',
	API_SOCKET_ENDPOINT_URL: 'https://homolog.dimo.com.br/',
	API_SOCKET_SUFIX_PATH: '/natercia/socket.io',
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'development':
			return dev;
		case 'production':
			return prod;
		case 'test':
			return test;
		default:
			break;
	}
};

export const env = getEnv();
