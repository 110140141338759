import fetch from 'auth/FetchInterceptor';
import {UPDATE_UNIDADE, UPDATE_USER} from 'redux/constants/User';
import {UPDATE_PERMISSOES} from 'redux/constants/Permissoes';
import store from 'redux/store';
import {SET_IS_LOADING} from 'redux/constants/Gui';

const menuService = {};

/*
    Injeta na store do usuário os dados do menu
*/

menuService.populate = menuData => {
	store.dispatch({
		type: UPDATE_USER,
		name: menuData.user.nome,
		role: 'Usuário',
		unidade: menuData.user.unidade,
		idusuario: menuData.user.id,
	});

	store.dispatch({
		type: UPDATE_PERMISSOES,
		permissoes: menuData.permissoes,
	});
};

menuService.populateMenuData = async function () {
	const userState = store.getState().user;
	const authState = store.getState().auth;

	store.dispatch({
		type: SET_IS_LOADING,
		isLoading: true,
	});

	if (!userState.name && authState.token) {
		try {
			const menuData = await menuService.getMenuData();
			menuService.populate(menuData);
			localStorage.setItem('user', JSON.stringify(menuData.user));
		} catch (e) {
			console.log(e);
		}
	}

	store.dispatch({
		type: SET_IS_LOADING,
		isLoading: false,
	});
};

menuService.populateUnidade = async function () {
	const menuData = await menuService.getMenuData();

	store.dispatch({
		type: UPDATE_UNIDADE,
		unidade: menuData.user.unidade,
	});
};

menuService.getMenuData = async function () {
	return fetch({
		url: '/v1/rest/menu',
		method: 'get',
	});
};

export default menuService;
