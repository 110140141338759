import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Layout} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import Logo from './Logo';
import NavSearch from './NavSearch';
import {onMobileNavToggle, toggleCollapsedNav} from 'redux/actions/Theme';
import {NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH} from 'constants/ThemeConstant';
import utils from 'utils';
import NavProfile from './NavProfile';
import NavNotification from './NavNotification';
import {Colors} from '../../styles';

const {Header} = Layout;

export const HeaderNav = props => {
	const {
		user,
		navCollapsed,
		mobileNav,
		navType,
		headerNavColor,
		toggleCollapsedNav,
		onMobileNavToggle,
		isMobile,
		currentTheme,
	} = props;
	const [searchActive, setSearchActive] = useState(false);

	const onSearchClose = () => {
		setSearchActive(false);
	};

	const onToggle = () => {
		if (!isMobile) {
			toggleCollapsedNav(!navCollapsed);
		} else {
			onMobileNavToggle(!mobileNav);
		}
	};

	const isNavTop = navType === NAV_TYPE_TOP;
	const mode = () => {
		if (!headerNavColor) {
			return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff');
		}
		return utils.getColorContrast(headerNavColor);
	};
	const navMode = mode();
	const getNavWidth = () => {
		if (isNavTop || isMobile) {
			return '0px';
		}
		if (navCollapsed) {
			return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
		} else {
			return `${SIDE_NAV_WIDTH}px`;
		}
	};

	useEffect(() => {
		if (!isMobile) {
			onSearchClose();
		}
	});

	return (
		<Header className={`app-header ${navMode}`} style={{backgroundColor: headerNavColor}}>
			<div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
				<Logo logoType={navMode} />
				<div
					className="nav"
					style={{
						width: `calc(100% - ${getNavWidth()})`,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}>
					<div className="nav-left">
						<ul className="ant-menu ant-menu-root ant-menu-horizontal">
							{isNavTop && !isMobile ? null : (
								<li
									className="ant-menu-item ant-menu-item-only-child"
									onClick={() => {
										onToggle();
									}}>
									{navCollapsed || isMobile ? (
										<MenuUnfoldOutlined className="nav-icon" style={{color: Colors.primary}} />
									) : (
										<MenuFoldOutlined className="nav-icon" style={{color: Colors.primary}} />
									)}
								</li>
							)}
						</ul>
					</div>

					<div className="nav-right">
						<NavNotification />
						<NavProfile />
						{/* <NavPanel direction={direction} /> Aqui que tem as configurações de tela, se quiser testar */}
					</div>
					<NavSearch active={searchActive} close={onSearchClose} />
				</div>
			</div>
		</Header>
	);
};

const mapStateToProps = ({theme, user}) => {
	const {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction} = theme;
	return {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, user: {...user}};
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle})(HeaderNav);
