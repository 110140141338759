import React from 'react';
import {Dropdown, Menu} from 'antd';
import {connect} from 'react-redux';
import {LogoutOutlined, UserOutlined} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import {Colors} from '../../styles';
import {signOut} from '../../redux/actions/Auth';

const menuItem = [];

const NavProfile = ({user, signOut}) => {
	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					<UserOutlined
						style={{fontSize: 30, color: Colors.primary, display: 'flex', alignItems: 'center'}}
					/>
					<div className="pl-3">
						<h4 style={{color: Colors.primary}} className="mb-0">
							{user?.name}
						</h4>
					</div>
				</div>
			</div>
			<Menu>
				{menuItem.map((el, i) => {
					return (
						<Menu.Item key={i}>
							<a href={el.path}>
								<Icon className="mr-3" type={el.icon} />
								<span className="font-weight-normal">{el.title}</span>
							</a>
						</Menu.Item>
					);
				})}
				<Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
					<span>
						<LogoutOutlined className="mr-3" />
						<span className="font-weight-normal">Sair</span>
					</span>
				</Menu.Item>
			</Menu>
		</div>
	);
	return (
		<Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
			<Menu className="d-flex align-item-center" mode="horizontal">
				<Menu.Item key="profile">
					<UserOutlined className="nav-icon mr-0" style={{color: Colors.primary}} />
				</Menu.Item>
			</Menu>
		</Dropdown>
	);
};

const mapStateToProps = ({user}) => {
	return {user: {...user}};
};

export default connect(mapStateToProps, {signOut})(NavProfile);
