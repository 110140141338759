import {ID_UNIDADE, UPDATE_UNIDADE, UPDATE_USER} from '../constants/User';

const initUser = {
	name: null,
	role: null,
	unidade: {idunidade: null, nome: null},
	idusuario: null,
};

const user = (state = initUser, action) => {
	switch (action.type) {
		case UPDATE_USER:
			console.log('=>(User.js:31) action', action);

			localStorage.setItem(ID_UNIDADE, action.unidade.idunidade);
			return {
				...state,
				name: action.name,
				role: action.role,
				unidade: action.unidade,
				idusuario: action.idusuario,
			};
		case UPDATE_UNIDADE:
			localStorage.setItem(ID_UNIDADE, action.unidade.idunidade);
			return {
				...state,
				unidade: action.unidade,
			};
		default:
			return state;
	}
};

export default user;
