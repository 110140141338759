export const fontFamily = 'Poppins';

export const regular = {
	fontWeight: '400',
	fontFamily: fontFamily,
};

export const medium = {
	fontWeight: '500',
	fontFamily: fontFamily,
};

export const strong = {
	fontWeight: '500',
	fontFamily: fontFamily,
};

export const semiBold = {
	fontWeight: '600',
	fontFamily: fontFamily,
};

export const bold = {
	fontWeight: '700',
	fontFamily: fontFamily,
};

export const extraBold = {
	fontWeight: '800',
	fontFamily: fontFamily,
};

export const black = {
	fontWeight: '900',
	fontFamily: fontFamily,
};

export const size12 = {
	fontSize: 12,
};

export const size14 = {
	fontSize: 14,
};

export const size16 = {
	fontSize: 16,
};

export const size18 = {
	fontSize: 18,
};

export const size20 = {
	fontSize: 20,
};

export const size24 = {
	fontSize: 24,
};
export const size28 = {
	fontSize: 28,
};

export const size36 = {
	fontSize: 36,
};
export const size32 = {
	fontSize: 32,
};

export const bold14 = {
	...bold,
	...size14,
};
export const bold20 = {
	...bold,
	...size20,
};
export const bold24 = {
	...bold,
	...size24,
};
export const bold28 = {
	...bold,
	...size24,
};

export const bold16 = {
	...bold,
	...size16,
};

export const bold18 = {
	...bold,
	...size18,
};

export const bold12 = {
	...bold,
	...size12,
};

export const bold36 = {
	...bold,
	...size36,
};

export const bold32 = {
	...bold,
	...size32,
};

export const regular16 = {
	...regular,
	...size16,
};

export const regular12 = {
	...regular,
	...size12,
};

export const regular14 = {
	...regular,
	...size14,
};

export const regular18 = {
	...regular,
	...size18,
};

export const regular20 = {
	...regular,
	...size20,
};

export const strong14 = {
	...strong,
	...size14,
};
export const strong16 = {
	...strong,
	...size16,
};

export const medium14 = {
	...medium,
	...size14,
};

export const medium16 = {
	...medium,
	...size16,
};

export const medium20 = {
	...medium,
	...size20,
};

export const medium18 = {
	...medium,
	...size18,
};

export const semiBold12 = {
	...semiBold,
	...size12,
};

export const semiBold14 = {
	...semiBold,
	...size14,
};

export const semiBold16 = {
	...semiBold,
	...size16,
};

export const semiBold18 = {
	...semiBold,
	...size18,
};

export const extraBold16 = {
	...extraBold,
	...size16,
};

export const black18 = {
	...black,
	...size18,
};
