export const primary = '#FF0F74';
export const secondary = '#FDF6F8';
export const hover = '#FF529B';
export const secondaryHover = '#FFCBDF';
export const active = '#C2155E';
export const secondaryActive = '#C2155E';
export const inactive = '#636363';

export const greylow = '#8F8F8F';
export const grey = '#9A9797';
export const inputBorder = '';
export const inputBorderHover = '#673178';
export const error = '#DB1F22';
export const error2 = '#EB4245';
export const error3 = '#FFF2F2';
export const sucess = '#38A569';
export const sucess2 = '#5DBE89';
export const sucess3 = '#E8FFF2';
export const white = '#ffffff';
export const neutral900 = '#202020';
export const neutral800 = '#5A5A5A';
export const neutral700 = '#959595';
export const neutral600 = '#a4a4a4';
export const neutral500 = '#b6b6b6';
export const neutral400 = '#c2c2c2';
export const neutral300 = '#d5d5d5';
export const neutral200 = '#e3e3e3';
export const neutral100 = '#F4F4F4';
export const rosa = '#FF0F74';
export const rosaLight = '#ff529b';
export const rosaForte = '#c2155e';
export const rosaClaro = '#ffcbdf';
export const rosaLight2 = '#fdf6f8';

