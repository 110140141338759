import React, {useState} from 'react';
import {Badge, Button, Dropdown, List, Menu, Tooltip} from 'antd';
import {BellOutlined, EyeOutlined} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex';
import {connect} from 'react-redux';
import moment from 'moment';
import {Colors} from '../../styles';

export const NavNotification = props => {
	const {notificacoes} = props;
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [idParaExcluir, setIdParaExcluir] = useState(null);

	const getNotificationBody = list => {
		return list.length > 0 ? (
			<List
				size="small"
				itemLayout="horizontal"
				dataSource={list}
				renderItem={item => (
					<List.Item className="list-clickable">
						<Flex alignItems="center">
							<div className="mr-3">
								<span className="text-gray-light">{item.mensagem}</span>
							</div>
							<small className="ml-auto">{moment(item.createdAt).format('DD/MM HH:mm')}</small>
							<Tooltip placement="bottom" title={'Marcar notificação como visualizada!'}>
								<Button
									className="text-primary"
									loading={loading && item.id === idParaExcluir}
									type="link"
									onClick={() => visualizaNotificao(item.id)}
									size="small">
									<EyeOutlined style={{color: Colors.primary}} />
								</Button>
							</Tooltip>
						</Flex>
					</List.Item>
				)}
			/>
		) : (
			<div className="empty-notification">
				<img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
				<p className="mt-3">Você já visualizou todas as notificações.</p>
			</div>
		);
	};

	// useEffect(() => {
	// 	setData(notificacoes.notificacoes);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props]);

	const handleVisibleChange = flag => {
		setVisible(flag);
	};

	const visualizaNotificao = async id => {
		setIdParaExcluir(null);
		setIdParaExcluir(id);
		setLoading(true);
		// await notificacaoService.visualizar({id: id});
		setLoading(false);
		setData(data.filter(item => item.id !== id));
	};

	const notificationList = (
		<div className="nav-dropdown nav-notification">
			<div className="nav-notification-header d-flex justify-content-between align-items-center">
				<h4 className="mb-0">Notificações</h4>
			</div>
			{/*<div className="nav-notification-body">{getNotificationBody(data)}</div>*/}
			{data.length > 0 ? <div className="nav-notification-footer"></div> : null}
		</div>
	);

	return (
		<Dropdown
			placement="bottomRight"
			overlay={notificationList}
			onVisibleChange={handleVisibleChange}
			visible={visible}
			trigger={['click']}>
			<Menu mode="horizontal">
				<Menu.Item key="notification">
					<Badge count={data.length}>
						<BellOutlined className="nav-icon mx-auto" type="bell" style={{color: Colors.primary}} />
					</Badge>
				</Menu.Item>
			</Menu>
		</Dropdown>
	);
};

const mapStateToProps = ({notificacoes}) => {
	return {
		notificacoes: notificacoes,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavNotification);
